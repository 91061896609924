import React, { useState, useEffect, useCallback } from 'react';
import reportsService from '../../../services/reports';
import loaderIcon from '../../../assets/loader.svg';
import invoiceIcon from '../../../assets/invoice-icon.png';

import MaskInput from '../../../components/Layout/MaskInput';

import debounce from 'lodash/debounce';

import Utils from '../../../components/Utils/Utils';
import ScrollableTable from '../../../components/DataGrid/DataGrid';

import ModalPDF from '../../../components/ModalPDF';

function TypeCurrencyView({ selectedDate }) {
  const [loader, setLoader] = useState(false);
  const [retentionReport, setRetentionReport] = useState(null);
  const [fields, setFields] = useState({});
  const [modalPDF, setModalPDF] = useState({status: false, url: ''});

  const dynamicFieldsCheck = (tax) => {
    const dynamicFields = {};
    tax.forEach((taxItem) => {
      dynamicFields[`taxID${taxItem.taxID}`] = {
        "supplierName": taxItem.supplierName,
        "constructionName": taxItem.constructionName,
        "taxID": taxItem.taxID,
        "invoiceNumber": taxItem.invoiceNumber,
        "inss": taxItem.inss,
        "issGramado": taxItem.issGramado,
        "irrf": taxItem.irrf,
        "csrf": taxItem.csrf,
        "dueDate": taxItem.dueDate,
        "expenseID": taxItem.expenseID,
        "paid": taxItem.paid,
        "paid_inss": taxItem.paid_inss,
        "paid_issGramado": taxItem.paid_issGramado,
        "paid_irrf": taxItem.paid_irrf,
        "paid_csrf": taxItem.paid_csrf,
        "pdfPath": taxItem.pdfPath
      };
    });

    // Update initialFields with dynamicFields
    setFields((prevFields) => ({
      ...prevFields,
      ...dynamicFields,
    }));
  }

  const debouncedUpdateRetention = useCallback(
    debounce((taxID, param, value) => {
      updateRetention(taxID, param, value);
    }, 500),
  []);

  const handleCloseModal = (e) => {
    setModalPDF({ status: false, url: '' });
  }
  
  useEffect(() => {
    const fetchForecastReport = async () => {
      try {
        if(typeof selectedDate !== 'object' && selectedDate !== null) {
          setRetentionReport([]);
          setLoader(true);
          const result = await reportsService.getRetention(`${selectedDate}-01_${selectedDate}-30`);
          
          if (Array.isArray(result.data)) {
            setRetentionReport(result.data);
            dynamicFieldsCheck(result.data);
          } else {
            console.error('Invalid data structure for retentionReport:', result);
          }
          setLoader(false);
        }
      } catch (error) {
        console.error('Error fetching expenses:', error);
      }
    };

    fetchForecastReport();
  }, [selectedDate]);

  const updateRetention = async (taxID, param, value) => {
    try {
      setLoader(true);
      await reportsService.updateRetention(taxID, {
        [param]: value
      });
      setLoader(false);
     } catch (error) {
      setLoader(false);
      console.error('Error fetching expenses:', error);
    }
  };

  const handleCheckboxChange = (e, taxID, param) => {
    const { checked } = e.target;
    setFields(prevFields => ({
      ...prevFields,
      [`taxID${taxID}`]: {
        ...prevFields[`taxID${taxID}`],
        [param]: checked // Update the param (paid_inss) with the checked value
      }
    }));
    debouncedUpdateRetention(taxID, param, checked);
  };

  const handleInputChange = (e, taxID, param) => {
    let name = '';
    let value = '';
    if (e.type !== 'change') {
      name = e.type;
      value = e.formattedValue;
    } else {
      value = e.target.value;
      name = e.target.name;
    }

    setFields((prevFields) => ({
      ...prevFields,
      [`taxID${taxID}`]: {
        ...prevFields[`taxID${taxID}`],
        [param]: value
      }
    }));
    debouncedUpdateRetention(taxID, param, parseFloat(value.replace(/[^\d,]/g, '').replace(',', '.')));
  };
  
  const dynamicColumns = [
    {
      Header: 'Fornecedor',
      accessor: 'supplierName',
      width: 80,
      textAlign: 'left',
    },
    {
      Header: 'Obra',
      accessor: 'constructionName',
      width: 50,
      textAlign: 'left',
    },
    {
      Header: 'Nº NF',
      accessor: 'invoiceNumber',
      width: 50,
      textAlign: 'left',
    },
    {
      Header: 'INSS',
      accessor: 'inss',
      width: 50,
      textAlign: 'left',
    },
    {
      Header: 'ISS Gramado',
      accessor: 'issGramado',
      width: 50,
      textAlign: 'left',
    },
    {
      Header: 'IRRF',
      accessor: 'irrf',
      width: 50,
      textAlign: 'left',
    },
    {
      Header: 'CSRF',
      accessor: 'csrf',
      width: 50,
      textAlign: 'left',
    },
    {
      Header: 'NF',
      accessor: 'invoice_bill',
      width: 50,
      textAlign: 'center',
    },
    // {
    //   Header: 'Recebido',
    //   accessor: 'received',
    //   width: 30,
    //   textAlign: 'center',
    // }
  ];

  const rows = retentionReport && retentionReport.map((item, index) => {
    return {
      constructionName: item.constructionName,
      supplierName: item.supplierName,
      invoiceNumber: item.invoiceNumber,
      inss: (
        <div className='wrapper-inputs-rt'>
          <MaskInput
            type="amount"
            defaultValue={Utils.currencyFormat(fields[`taxID${item.taxID}`].inss)}
            placeholder={"R$ 0,00"}
            onValueChange={(e) => handleInputChange(e, item.taxID, 'inss')}
            alignment={'left'}
          />
          <input
            type='checkbox'
            name={`tax-inss-${index}`}
            checked={fields[`taxID${item.taxID}`]?.paid_inss}
            onChange={(e) => handleCheckboxChange(e, item.taxID, 'paid_inss')}
          />
        </div>
      ),
      issGramado: (
        <div className='wrapper-inputs-rt'>
          <MaskInput
            type="amount"
            defaultValue={Utils.currencyFormat(fields[`taxID${item.taxID}`].issGramado)}
            placeholder={"R$ 0,00"}
            onValueChange={(e) => handleInputChange(e, item.taxID, 'issGramado')}
            alignment={'left'}
          />
          <input
            type='checkbox'
            name={`tax-issGramado-${index}`}
            checked={fields[`taxID${item.taxID}`]?.paid_issGramado}
            onChange={(e) => handleCheckboxChange(e, item.taxID, 'paid_issGramado')}
          />
        </div>
      ),
      irrf: (
        <div className='wrapper-inputs-rt'>
          <MaskInput
            type="amount"
            defaultValue={Utils.currencyFormat(fields[`taxID${item.taxID}`].irrf)}
            placeholder={"R$ 0,00"}
            onValueChange={(e) => handleInputChange(e, item.taxID, 'irrf')}
            alignment={'left'}
          />
          <input
            type='checkbox'
            name={`tax-irrf-${index}`}
            checked={fields[`taxID${item.taxID}`]?.paid_irrf}
            onChange={(e) => handleCheckboxChange(e, item.taxID, 'paid_irrf')}
          />
        </div>
      ),
      csrf: (
        <div className='wrapper-inputs-rt'>
          <MaskInput
            type="amount"
            defaultValue={Utils.currencyFormat(fields[`taxID${item.taxID}`].csrf)}
            placeholder={"R$ 0,00"}
            onValueChange={(e) => handleInputChange(e, item.taxID, 'csrf')}
            alignment={'left'}
          />
          <input
            type='checkbox'
            name={`tax-csrf-${index}`}
            checked={fields[`taxID${item.taxID}`]?.paid_csrf}
            onChange={(e) => handleCheckboxChange(e, item.taxID, 'paid_csrf')}
          />
        </div>
      ),
      invoice_bill: fields[`taxID${item.taxID}`]?.pdfPath.indexOf('https') !== -1 && <button style={{ background: 'transparent', border: '0' }} onClick={() =>setModalPDF({ status: true, url: fields[`taxID${item.taxID}`]?.pdfPath })}><img style={{ width: '35px' }} src={invoiceIcon} alt="Acessar documento." /></button>,
      received: (
        <input
          type='checkbox'
          name={`tax-${item.paid}`}
          checked={fields[`taxID${item.taxID}`]?.paid_paid}
          onChange={(e) => handleCheckboxChange(e, item.taxID, 'retention')}
        />
      ),
    }
  });
  
  return (
    <>
      <div className='wrapper-report'>
          {
              loader || (retentionReport === null) ? (
                  <img src={loaderIcon} alt="Carregando..." width={100} height={100} />
              ) : (
                  retentionReport && <ScrollableTable headers={dynamicColumns} height={'80vh'} dataRows={rows}  />
              )
          }
      </div>
      {
        modalPDF.status && <ModalPDF onCloseModal={handleCloseModal} url={modalPDF.url} />
      }
    </>
  );
}

export default TypeCurrencyView;
