import React, { useState, useEffect, useCallback } from 'react';
import reportsService from '../../../services/reports';
import loaderIcon from '../../../assets/loader.svg';
import MaskInput from '../../../components/Layout/MaskInput';
import Utils from '../../../components/Utils/Utils';
import ScrollableTable from '../../../components/DataGrid/DataGrid';

import debounce from 'lodash/debounce';

function TypeCurrencyView({ selectedDate, loaded, totalMoney }) {
  const [loader, setLoader] = useState(false);
  const [dateSelected, setDateSelected] = useState();
  const [typeMoneyReport, setTypeMoneyReport] = useState(null);
  const [forecastReport, setForecastReport] = useState(null);

  const debouncedUpdateForecast = useCallback(
    debounce((ConstructionID, value) => {
      updateForecast(ConstructionID, value);
    }, 1500)
  , [dateSelected]);

  const updateForecast = async (ConstructionID, value) => {
    try {
      const numericAmount= value.replace(/[^\d,]/g, '').replace(',', '.');
      await reportsService.updateMonthForecast(ConstructionID, {
        monthlyExpense: parseFloat(numericAmount),
        forecastMonth: `${dateSelected === undefined ? selectedDate : dateSelected}-01`
      });

    } catch (error) {
      console.error('Error updating forecast:', error);
    }
  };
  
  useEffect(() => {
    const fetchForecastReport = async () => {
      try {
        if(typeof selectedDate !== 'object' && selectedDate !== null && JSON.stringify(selectedDate) !== '{}') {
          setTypeMoneyReport([]);
          setDateSelected(selectedDate);
          const result = await reportsService.getMonthlyTypeMoneyReport(`${selectedDate}`);
          const resultForecast = await reportsService.getForecastReport(`${selectedDate}-01`);
          
        
          if (Array.isArray(result.data)) {
            setTypeMoneyReport(result.data);
            loaded(false);
          } else {
            console.error('Invalid data structure for typeMoneyReport:', result);
          }

          if (Array.isArray(resultForecast.data)) {
            setForecastReport(resultForecast.data);
          }
          setLoader(false);
        }
      } catch (error) {
        console.error('Error fetching expenses:', error);
      }
    };
    loaded(true);
    fetchForecastReport();
  }, [selectedDate]);

  const dynamicColumns = [
    {
      Header: 'Obra/Centro de Custo',
      accessor: 'ConstructionName',
      width: 150,
      textAlign: 'left',
    },
    {
      Header: 'Banco',
      accessor: 'TotalPaidByInternet',
      width: 100,
      textAlign: 'left',
    },
    {
      Header: 'Dinheiro',
      accessor: 'TotalPaid',
      width: 100,
      textAlign: 'left',
    },
    {
      Header: 'Total',
      accessor: 'Total',
      width: 100,
      textAlign: 'left',
    },
    {
      Header: 'Previsão Mensal',
      accessor: 'totalForecastMonth',
      width: 100,
      textAlign: 'left',
    }
  ];

  const rows = typeMoneyReport && typeMoneyReport.map((item, index) => {
    return {
      ConstructionName: Utils.currencyFormat(item.ConstructionName),
      TotalPaidByInternet: item.TotalPaidByInternet ? Utils.currencyFormat(item.TotalPaidByInternet) : '-',
      TotalPaid: item.TotalPaid ? Utils.currencyFormat(item.TotalPaid) : '-',
      Total: item.TotalPaid + item.TotalPaidByInternet ? Utils.currencyFormat(item.TotalPaid + item.TotalPaidByInternet) : '',
      totalForecastMonth: (
        <MaskInput
          type="amount"
          defaultValue={Utils.currencyFormat(item.ForecastMonth !== null ? item.ForecastMonth : 0)}
          placeholder={"R$ 0,00"}
          onValueChange={(e) => handleInputChange(e, item.ConstructionID, 'ForecastMonth')}
          tabIndex={8}
        />
      ),
    }
  });

  const handleInputChange = (e, ConstructionID, name) => {
    setTypeMoneyReport((prevFields) => {
      const index = prevFields.findIndex(c => c.ConstructionID === ConstructionID);
      if (index !== -1) {
        // If the ConstructionID is found, create a new array with the modified object at the found index
        return [
          ...prevFields.slice(0, index), // elements before the modified object
          {
            ...prevFields[index], // existing object
            ForecastMonth: e.formattedValue // updated ForecastMonth
          },
          ...prevFields.slice(index + 1) // elements after the modified object
        ];
      } else {
        // If the ConstructionID is not found, return the original array unchanged
        return prevFields;
      }
    });
    debouncedUpdateForecast(ConstructionID, e.formattedValue);
  };

  const totalPaidByInternetSum = typeMoneyReport ? typeMoneyReport.reduce((sum, item) => sum + item.TotalPaidByInternet, 0) : 0;
  const totalPaidSum = typeMoneyReport ? typeMoneyReport.reduce((sum, item) => sum + item.TotalPaid, 0) : 0;
  // const totalForecastMonth = typeMoneyReport ? typeMoneyReport.reduce((sum, item) => sum + item.ForecastMonth, 0) : 0;
  const totalForecastMonth = typeMoneyReport 
  ? typeMoneyReport.reduce((sum, item) => {
      let forecastMonth = item.ForecastMonth;

      if (forecastMonth === null || forecastMonth === undefined) {
        return sum;
      }

      // Ensure forecastMonth is a string
      forecastMonth = forecastMonth.toString();

      // Replace commas with dots
      forecastMonth = forecastMonth.replace(',', '.');

      // Remove any non-numeric characters except for period and minus sign
      const cleanedValue = parseFloat(forecastMonth.replace(/[^0-9.-]+/g, ''));

      // Check if cleanedValue is a valid number, otherwise use 0
      const numericValue = isNaN(cleanedValue) ? 0 : cleanedValue;

      return sum + numericValue;
    }, 0)
  : 0;

  totalMoney( totalPaidSum );
  const tableFooter = (
    <>
      <tr className='tfoot'>
        <td><strong>Total:</strong></td>
        <td><strong>{ totalPaidByInternetSum ? Utils.currencyFormat(totalPaidByInternetSum) : '-' }</strong></td>
        <td><strong>{ totalPaidSum ? Utils.currencyFormat(totalPaidSum) : '-' }</strong></td>
        <td><strong>{ (totalPaidSum + totalPaidByInternetSum) ? Utils.currencyFormat(totalPaidSum + totalPaidByInternetSum) : '-' }</strong></td>
        <td><strong>{ Utils.currencyFormat(totalForecastMonth) }</strong></td>
      </tr>
    </>
  );
  
  return (
    <>
      <div className='wrapper-report'>
          {
              loader && (typeMoneyReport === null) ? (
                  <img src={loaderIcon} alt="Carregando..." width={100} height={100} />
              ) : (
                  typeMoneyReport && <ScrollableTable headers={dynamicColumns} footer={tableFooter} height={'100%'} dataRows={rows}  />
              )
          }
      </div>
    </>
  );
}

export default TypeCurrencyView;
