import React, { useState } from "react";
import PrimaryButton from '../../components/Layout/PrimaryButton';
import loaderIcon from '../../assets/loader.svg';
import MaskInput from '../../components/Layout/MaskInput';
import Utils from '../../components/Utils/Utils';

import Select from 'react-select';

import './ModalLedger.scss';

function ModalAttach({ onCloseModal, onFormSubmit }) {
  const getCurrentDate = () => {
    const today = new Date();
    const day = String(today.getDate()).padStart(2, '0');
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const year = today.getFullYear();
    return `${year}-${month}-${day}`;
  };
  
  const initialFields = { 
    entryDate: getCurrentDate(),
    description: '',
    incomingCash: '',
    expenseType: 'Despesa' // Default value for expense type
  };

  const [fields, setFields] = useState({ ...initialFields });
  const [successForm, setSuccessForm] = useState(false);
  const [loading, setLoader] = useState(false);

  const handleInputChange = (e) => {
    let name = '';
    let value = '';
    if(e?.type === 'amount') {
      name = 'incomingCash';
      value = e.formattedValue;
    } else {
      name = e.target.name;
      value = e.target.value;
    }
    
    setFields((prevFields) => ({
      ...prevFields,
      [name]: value
    }));
  };

  const handleSelectChange = (selectedOption, name) => {
    setFields((prevFields) => ({
      ...prevFields,
      [name]: selectedOption
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let { entryDate, description, incomingCash, expenseType } = fields;
    
    if (expenseType.value === 'Despesa') {
      incomingCash = -Math.abs(parseFloat(incomingCash = incomingCash.replace(/[^\d,]/g, '').replace(',', '.'))); // Ensure negative
    } else {
      incomingCash = Math.abs(parseFloat(incomingCash = incomingCash.replace(/[^\d,]/g, '').replace(',', '.'))); // Ensure positive
    }

    onFormSubmit({ entryID: 0, entryDate, description, incomingCash, expenseType });
    onCloseModal(false);
  };

  const expenseTypeOptions = [
    { value: 'Despesa', label: 'Saída' },
    { value: 'Receita', label: 'Entrada' }
  ];

  return (
    <div className='modal-ledger'>
      <div className="inner-content" style={{maxWidth: '700px', zIndex: 10}}>
        <>
          <h1>{ 'Incluir' }</h1>
          <button className="btn-close" style={{border: '2px solid #fff', top: '-10px', right: '-10px' }} onClick={() => onCloseModal(false)}>X</button>
          {
            !successForm && !loading ? (
              <>
                <div className="wrapper-form">
                  <label>
                    Tipo
                    <div className={`wrapper-select`} style={{width: 120, marginTop: 5, boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)'}}>
                      <Select
                        options={expenseTypeOptions}
                        value={fields.expenseType}
                        onChange={(e) => handleSelectChange(e, 'expenseType')}
                        placeholder="Selecione"
                        menuPortalTarget={document.body}
                        menuPosition={'fixed'}
                        styles={{
                          option: (provided, state) => ({
                            ...provided,
                            fontSize: '11px'
                          })}
                        }
                      />
                    </div>
                  </label>
                  <label>
                    Data
                    <input
                      type="date"
                      name="entryDate"
                      value={fields.entryDate}
                      onChange={handleInputChange}
                    />
                  </label>
                  <label>
                    Descrição
                    <input
                      type="text"
                      name="description"
                      value={fields.description}
                      onChange={handleInputChange}
                    />
                  </label>
                  <label>
                    Valor
                    <MaskInput
                      type="amount"
                      defaultValue={Utils.currencyFormat(fields.incomingCash)}
                      placeholder={"R$ 0,00"}
                      onValueChange={(e) => handleInputChange(e, fields.incomingCash, 'incomingCash')}
                    />
                  </label>
                </div>
                <PrimaryButton fullWidth onClick={handleSubmit} tabIndex={11}>Salvar</PrimaryButton>
              </>
            ) : loading ? (
              <img className="loader" width={100} height={100} src={loaderIcon} alt="Carregando..." />
            ) : (
              <div className="wrapper-form">
                <h2>
                  { 'Saída incluída!' }  
                </h2>
              </div>
            )
          }
        </>
      </div>
    </div>
  );
}

export default ModalAttach;
