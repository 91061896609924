import React, { Component } from 'react'


import './ChangePasswordSuccess.scss'

class ChangePasswordSuccess extends Component {
  constructor(props) {
    super(props)

    this.state = {
      clickedNext: false,
    }

    this.handleClick = this.handleClick.bind(this)
  }

  handleClick() {
    this.setState({ clickedNext: true })
  }

  render() {
      return (
        <div className='change-password-success-screen'>
          <p className="login-box__subheader">{ 'Sua senha foi alterada com sucesso!' }</p>
        </div>
      )
   }
}

export default ChangePasswordSuccess
