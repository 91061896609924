import axios from 'axios'

import config from '../config'

const getAll = () => {
  return axios.get(`${config.baseUrl}/constructions`, {
    headers: {
      'Authorization': `Bearer ${ sessionStorage.getItem('DonFelipeToken') }`,
    },
  })
}

const getById = id => {
  return axios.get(`${config.baseUrl}/constructions/${id}`, {
    headers: {
      'Authorization': `Bearer ${sessionStorage.getItem('DonFelipeToken')}`,
    },
  })
}

const createConstruction = construction => {
  return axios.post(`${config.baseUrl}/constructions`, construction, {
    headers: {
      'Authorization': `Bearer ${sessionStorage.getItem('DonFelipeToken')}`,
    },
  })
}

const createConstructionMtDB = (construction) => {
  return axios.post(`${config.baseUrl}/constructions/createConstructionMtDB`, construction, {
    headers: {
      'Authorization': `Bearer ${sessionStorage.getItem('DonFelipeToken')}`,
      'AuthorizationM': `Bearer ${sessionStorage.getItem('DonFelipeTokenM')}`,
    },
  })
}

const updateConstruction = (constructionId, updates) => {
  return axios.put(`${config.baseUrl}/constructions/${constructionId}`, updates, {
    headers: {
      'Authorization': `Bearer ${sessionStorage.getItem('DonFelipeToken')}`,
    },
  })
}

export default { getAll, getById, createConstruction, updateConstruction, createConstructionMtDB }
