const isLoggedIn = () => {
  const token = sessionStorage.getItem('DonFelipeToken')
  return (token !== null && token !== '')
}

const isFirstAccess = () => {
  const firstAccess = sessionStorage.getItem('DonFelipeFirstAccess')
  return firstAccess === 'true'
}

const isDonFelipeUser = () => {
  const userPermission = sessionStorage.getItem('DonFelipePermission')
  return userPermission === 'DonFelipe'
}

const isAdminUser = () => {
  const userPermission = sessionStorage.getItem('DonFelipePermission')
  return userPermission === 'admin'
}

const isRegularUser = () => {
  const userPermission = sessionStorage.getItem('DonFelipePermission')
  return userPermission === 'user'
}

const getName = () => {
  const name = sessionStorage.getItem('DonFelipeUsername')
  return name === null ? '' : name
}

const getUserId = () => {
  const id = sessionStorage.getItem('DonFelipeUserId')
  return id === null ? '' : id
}

const getCompanyId = () => {
  const id = sessionStorage.getItem('DonFelipeCompanyId')
  return id === null ? '' : id
}

export default {
  isLoggedIn,
  isFirstAccess,
  isRegularUser,
  isAdminUser,
  isDonFelipeUser,
  getName,
  getCompanyId,
  getUserId
}
