import React, { useState, useEffect, useCallback } from 'react';
import reportsService from '../../../services/reports';
import loaderIcon from '../../../assets/loader.svg';
import debounce from 'lodash/debounce';

import MaskInput from '../../../components/Layout/MaskInput';
import ScrollableTable from '../../../components/DataGrid/DataGrid';
import Utils from '../../../components/Utils/Utils';

function AnnualView() {
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [annualReport, setAnnualReport] = useState(null);
  const [loader, setLoader] = useState(false);
  const [totalYearlyForecast, setTotalYearlyForecast] = useState(0);

  useEffect(() => {
    const fetchAnnualReport = async () => {
      try {
        setLoader(true);
        const result = await reportsService.getAnnualReport(selectedYear.toString());
        setLoader(false);
        if (Array.isArray(result.data)) {
          const cleanedData = result.data.map(item => ({
            ...item,
            month: Object.fromEntries(
              Object.entries(item.month).filter(([key, value]) => key !== '' && key !== null)
            )
          }));
          setAnnualReport(cleanedData);
        } else {
          console.error('Invalid data structure for annualReport:', result);
        }
      } catch (error) {
        console.error('Error fetching expenses:', error);
      }
    };

    fetchAnnualReport();
  }, [selectedYear]);

  useEffect(() => {
    if (annualReport) {
      let totalForecast = annualReport.reduce((total, item) => {
        return total + (item.yearlyExpense ? parseFloat(item.yearlyExpense) : 0);
      }, 0);
      setTotalYearlyForecast(totalForecast);
    }
  }, [annualReport]);

  // Function to handle input change
  const handleInputChange = (e, constructionID, name) => {
    const numericAmount = e.formattedValue.length ? e.formattedValue.replace(/[^\d,]/g, '').replace(',', '.') : '0';
  
    // Update the local annualReport state for immediate UI feedback
    const updatedReport = annualReport.map(item => {
      if (item.constructionID === constructionID) {
        return {
          ...item,
          yearlyExpense: parseFloat(numericAmount)
        };
      }
      return item;
    });
  
    setAnnualReport(updatedReport); // Update local state first for UI feedback
  
    // Debounced update to backend
    debouncedUpdateForecast(constructionID, numericAmount);
  };

  // Function to update the forecast with debounce
  const debouncedUpdateForecast = useCallback(
    debounce((ConstructionID, value) => {
      updateForecast(ConstructionID, value);
    }, 1500),
    []
  );

  // Function to update the forecast
  const updateForecast = async (ConstructionID, value) => {
    try {
      await reportsService.updateYearForecast(ConstructionID, {
        monthlyExpense: parseFloat(value),
        forecastYear: `${selectedYear}`
      });
    } catch (error) {
      console.error('Error updating forecast:', error);
    }
  };

  // Render loading or error state while waiting for data
  if (annualReport === null) {
    return <img className="loader" src={loaderIcon} alt="Carregando..." />;
  }

  if (annualReport instanceof Error) {
    return <div>Error fetching data: {annualReport.message}</div>;
  }

  // Extract unique months from the data
  const uniqueMonths = Array.from(
    new Set(
      annualReport.reduce((months, item) => [...months, ...Object.keys(item.month)], [])
    )
  );

  const monthsInPortuguese = [
    "Jan", "Fev", "Mar", "Abr", "Mai", "Jun",
    "Jul", "Ago", "Set", "Out", "Nov", "Dez"
  ];

  // Define columns dynamically
  const dynamicColumns = [
    {
      Header: 'Obra/CC',
      accessor: 'constructionName',
      width: 90,
      textAlign: 'left',
    },

    {
      Header: `Previsão`,
      accessor: 'yearlyExpenseInput',
      width: 70,
      textAlign: 'left',
    },

    // Dynamically generate columns for each unique month
    ...uniqueMonths
    // .filter(month => month && month.includes('-'))
    .map(month => ({
      Header: `${monthsInPortuguese[(parseInt(month.split('-')[1], 10) - 1)]}/${month.split('-')[0]}` ,
      accessor: `month.${month}`, // Use the accessor to access nested properties
      width: 50, // Adjust the width as needed
      textAlign: 'left',
    })),
    {
      Header: 'Total Ano',
      accessor: 'totalYear',
      width: 80,
      textAlign: 'left',
    },
  ];

  let totalConstructionYear = 0;

  const formattedData = annualReport.map((item) => {
    const formattedItem = {
      constructionName: item.constructionName,
    };

    // Handling the yearly expense input
    item['yearlyExpenseInput'] = (
      <MaskInput
        type="amount"
        defaultValue={Utils.currencyFormat(item.yearlyExpense ? item.yearlyExpense : 0)}
        placeholder={"R$ 0,00"}
        onValueChange={(e) => handleInputChange(e, item.constructionID, 'yearlyExpense')}
      />
    );

    let totalYear = 0;
    Object.entries(item.month).forEach(([month, value]) => {
      const cleanedValue = String(value).replace('R$', '').replace(/\./g, '').replace(',', '.').trim();
      const valueFix = cleanedValue === '-' ? 0 : parseFloat(cleanedValue);

      if (isNaN(valueFix)) {
        console.error(`Invalid value for month ${month}: ${value}`);
      }
      
      formattedItem[`month.${month}`] = isNaN(valueFix) ? 0 : valueFix;
      totalYear += isNaN(valueFix) ? 0 : valueFix;
    });
    totalConstructionYear += totalYear;
    item.totalYear = totalYear ? totalYear.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }) : '-';
    return formattedItem;
  });

  for (const [key, value] of Object.entries(annualReport)) {
    for (const [keyM, valueMonth] of Object.entries(value.month)) {
      if (valueMonth === 0) {
        annualReport[key].month[keyM] = "-";
      } else {
        annualReport[key].month[keyM] = valueMonth.toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        });
      }
    }
  }

  const tableFooter = (
    <>
      <tr className='tfoot'>
        <td width={360}><strong>Total:</strong></td>
        <td><strong>{totalYearlyForecast.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</strong></td>
        {uniqueMonths.map(month => (
          <td key={month} width={80}>
            <strong>
              {
                Object.values(formattedData.reduce((totals, item) => {
                const value = item[`month.${month}`] === '-' ? 0 : item[`month.${month}`];
                totals[month] = (totals[month] || 0) + value;
                return totals;
              }, {})).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
            </strong>
          </td>
        ))}
        <td><strong>{totalConstructionYear.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</strong></td>
      </tr>
    </>
  );

  // Render the data
  return (
    <>
      <div className='wrapper- report' style={{padding: '0 20px'}}>
        {/* Dropdown to select the year */}
        <div className='wrapper-select'>
          <select
            id="yearSelect"
            value={selectedYear}
            onChange={(e) => setSelectedYear(parseInt(e.target.value, 10))}
          >
            {/* Generate options for the last 5 years, adjust as needed */}
            {Array.from({ length: 5 }, (_, index) => new Date().getFullYear() - index).map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </select>
        </div>

        {
          loader ? (
            <img src={loaderIcon} alt="Carregando..." width={100} height={100} />
          ) : (
            <ScrollableTable headers={dynamicColumns} height={'84vh'} dataRows={annualReport} footer={tableFooter}  />
          )
        }
      </div>
    </>
  );
}

export default AnnualView;
