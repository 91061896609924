import React, { useState, useEffect } from "react"
import companiesService from '../../services/companies';
import ScrollableTable from '../../components/DataGrid/DataGrid';

import './ModalCompanies.scss';

import editIcon from '../../assets/edit-icon.png';

function ModalCompanies({onCloseModal, companyData}) {
  const [companies, setCompanies] = useState([]);
  const [deleteSup, setDelete] = useState(0);

  const TableHeaders = [{
    Header: '-',
    columns: [
      {
          Header: 'Empresa',
          accessor: 'company',
          textAlign: 'left',
          width: 220,
      },
      {
        Header: 'Ações',
        accessor: 'action',
        textAlign: 'left',
        width: 120,
      }
    ]
  }];

  const rows = companies.map((value, index) => {
    return {
      company: value.name,
      action: (<>
        {/* <button style={{ float: 'right' }} onClick={() => setDelete(1)}><img src={deleteIcon} style={{ width: '18px', height: '18px' }} /></button> */}
        <button style={{ float: 'right' }} onClick={() => companyData(companies[index])}><img src={editIcon} alt="Editar Fornecedor" style={{ width: '18px', height: '18px' }} /></button>
      </>),
      key: index,
    }
  });

  useEffect(() => {
    const getCompanies = async () => {
      try {
        const company = await companiesService.getAll();
        setCompanies(company.data);
      } catch (error) {
        console.error('Error fetching expenses:', error);
      }
    };

    getCompanies();
  }, []);

  return (
    <>
      <div className='modal-companies'>
        <div className="inner-content">
          {!deleteSup ? (
            <>
              <button className="btn-close" onClick={() => onCloseModal(false)}>X</button>
              <h3>Empresas Cadastradas</h3>
              {rows.length > 0 ? (
                <ScrollableTable headers={TableHeaders} height={'60vh'} dataRows={rows} />
              ) : (
                <p>Carregando...</p>
              )}
            </>
          ) : (
            <>
              <div className="wrapper-delete">
                <h3>Excluir Empresa?</h3>
                <button className={'btn--primary'}>Sim</button>
                <button className={'btn--primary'} onClick={() => setDelete(0)}>Não</button>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default ModalCompanies;
