import React, { Component } from 'react'
import { Link, Redirect } from 'react-router-dom'

//import LoginBox from '../../components/LoginBox'
import PrimaryButton from '../../components/Layout/PrimaryButton'
import logo from '../../assets/logo-white.jpg';
import './ForgotPasswordSuccess.scss'

class ForgotPasswordSuccess extends Component {
  constructor(props) {
    super(props)

    this.state = {
      clickedNext: false,
    }

    this.handleClick = this.handleClick.bind(this)
  }

  handleClick() {
    this.setState({ clickedNext: true })
  }

  render() {
    if (this.state.clickedNext) {
      return <Redirect to='/login' />
    } else {
      return (
        <div className='forgot-password-success-screen'>
          <div className="login-box">
            <img src={logo} className="App-logo" alt="logo" />
            <p className="login-box__subheader">{'Email enviado. Em alguns instantes você receberá seu e-mail com a nova senha.'}</p>
            <PrimaryButton fullWidth onClick={ this.handleClick }>Próximo</PrimaryButton>
            <Link to='/forgotPassword' className="forgot-password-success-screen__link">Não recebi o e-mail</Link>
          </div>
        </div>
      )
    }
  }
}

export default ForgotPasswordSuccess
