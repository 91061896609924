import React, { Component } from 'react'
import { Link, Redirect } from 'react-router-dom'
import logo from '../../assets/logo-white.jpg';
import loaderIcon from '../../assets/loader.svg';
import PrimaryButton from '../../components/Layout/PrimaryButton'

import loginService from '../../services/login'

import './ForgotPassword.scss'

class ForgotPassword extends Component {
  constructor(props) {
    super(props)

    this.state = {
      email: '',
      isLoading: false,
      hasErrors: false,
      isSuccess: false,
    }

    this.handleEmailChange = this.handleEmailChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleEmailChange(e) {
    this.setState({ email: e.target.value })
  }

  handleSubmit(e) {
    e.preventDefault()

    const params = {
      email: this.state.email,
    }

    this.setState({ isLoading: true })
    
    loginService.forgotPassword(params).then(response => {
      this.setState({ isSuccess: true })
    }).catch(error => {
      console.log(error)
      this.setState({ hasErrors: true })
    }).then(() => {
      this.setState({ isLoading: false })
    })
  }

  render() {
    const errorMessage = () => {
      if (this.state.hasErrors) {
        return <p className="login-screen__has-error" style={{color: 'red'}}>E-mail inválido</p>
      } else {
        return null
      }
    }

    if (this.state.isSuccess) {
      return <Redirect to='/forgotPasswordSuccess' />
    } else {
      return (
        <div className='login-screen'>
            <img src={logo} className="App-logo" alt="logo" />
            <p className="login-box__subheader">{ 'Preencha com o e-mail cadastrado para recuperar a senha' }</p>
            <form className="login-screen__form" action="#">
              {errorMessage()}
              <input
                className="login-screen__input"
                type="text"
                placeholder="E-mail"
                onChange={this.handleEmailChange}
                value={this.state.email}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    this.handleSubmit(e)
                  }
                }}
              />
              <PrimaryButton fullWidth onClick={this.handleSubmit} disabled={this.state.isLoading}>
                Enviar nova senha por e-mail
              </PrimaryButton>
            </form>
          
        </div>
      )
    }
  }
}


export default ForgotPassword;
