import React from "react";
import './ModalPDF.scss';

function getRandomNumber() {
  return Math.floor(Math.random() * 1000000000000); // Generate a random number up to 12 digits
}

function ModalPDF({ onCloseModal, url }) {
  
  const urlConcat = url.indexOf('metodochomolog') !== -1 ? url +  `?sv=2018-03-28&amp;ss=bfqt&amp;srt=sco&amp;sp=rwdlacup&amp;se=2999-01-14T19:19:45Z&amp;st=2019-01-14T11:19:45Z&amp;spr=https,http&amp;sig=%2F3NfuGQmSjuKHgFxXPPfKQ2uAFt8P5pfbzdEh6okHQY%3D&amp;no-cache=${getRandomNumber()}#toolbar=1&amp;navpanes=0` :
  url + `?sv=2018-03-28&ss=bfqt&srt=sco&sp=rwdlacup&se=2999-01-11T03:42:56Z&st=2019-01-10T19:42:56Z&spr=https,http&sig=K57wu%2BMy2TrhG8UC4L0hbmXV95kwZ7fJOe3nPRWZ5dc%3D&no-cache=${getRandomNumber()}#toolbar=1&navpanes=0`;
  const htmlContent = `<iframe 
                          className="doc-details__doc-iframe" 
                          title="Documento" 
                          name="doc-iframe" 
                          id="doc-iframe" 
                          frameBorder="0" 
                          border="0" 
                          cellSpacing="0"
                          style="width: 100%; height: 80vh;"
                          src="${urlConcat}"
                        ></iframe>`;
  return (
    <div className='modal-suppliers' onClick={() => onCloseModal(false)}>
      <div className="inner-content" style={{maxWidth: '800px'}}>
        <>
          <button className="btn-close" style={{border: '2px solid #fff', top: '-10px', right: '-10px' }} onClick={() => onCloseModal(false)}>X</button>
          {url && 
            <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
          }
        </>
      </div>
    </div>
  );
}

export default ModalPDF;
