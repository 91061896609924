const validatePasswords = (password, passwordConfirmation) => {
  return password.length > 7 && passwordConfirmation.length > 7
}

const passwordsMatch = (password, passwordConfirmation) => {
  return password === passwordConfirmation
}

export default {
  validatePasswords,
  passwordsMatch,
}
