import axios from 'axios'

import config from '../config'

const getAnnualReport = monthSelected => {
  return axios.get(`${config.baseUrl}/reports/annual/${monthSelected}`, {
    headers: {
      'Authorization': `Bearer ${sessionStorage.getItem('DonFelipeToken')}`,
    },
  })
}

const getForecastReport = monthSelected => {
  return axios.get(`${config.baseUrl}/reports/forecast/${monthSelected}`, {
    headers: {
      'Authorization': `Bearer ${sessionStorage.getItem('DonFelipeToken')}`,
    },
  })
}

const updateMonthForecast = (entryId, updates) => {
  return axios.put(`${config.baseUrl}/reports/forecast/month/${entryId}`, updates, {
    headers: {
      'Authorization': `Bearer ${sessionStorage.getItem('DonFelipeToken')}`,
    },
  })
}

const updateYearForecast = (entryId, updates) => {
  return axios.put(`${config.baseUrl}/reports/forecast/year/${entryId}`, updates, {
    headers: {
      'Authorization': `Bearer ${sessionStorage.getItem('DonFelipeToken')}`,
    },
  })
}

const getMonthlyTypeMoneyReport = monthSelected => {
  return axios.get(`${config.baseUrl}/reports/monthlytypemoney/${monthSelected}`, {
    headers: {
      'Authorization': `Bearer ${sessionStorage.getItem('DonFelipeToken')}`,
    },
  })
}

const getMonthlyTotalByCompany = monthSelected => {
  return axios.get(`${config.baseUrl}/reports/monthlytotalbycompany/${monthSelected}`, {
    headers: {
      'Authorization': `Bearer ${sessionStorage.getItem('DonFelipeToken')}`,
    },
  })
}

const getSuplierConstructionReport = filters => {
  return axios.post(`${config.baseUrl}/reports/suplierConstruction/`, filters, {
    headers: {
      'Authorization': `Bearer ${sessionStorage.getItem('DonFelipeToken')}`,
    },
  })
}

/* retention */
const getRetention = dateSelected => {
  return axios.get(`${config.baseUrl}/reports/retention/${dateSelected}`, {
    headers: {
      'Authorization': `Bearer ${sessionStorage.getItem('DonFelipeToken')}`,
    },
  })
}

const updateRetention = (entryId, updates) => {
  return axios.put(`${config.baseUrl}/reports/retention/${entryId}`, updates, {
    headers: {
      'Authorization': `Bearer ${sessionStorage.getItem('DonFelipeToken')}`,
    },
  })
}

  /* cashLedger */
  const cashLedger = monthSelected => {
    return axios.get(`${config.baseUrl}/reports/cashLedger/${monthSelected}`, {
      headers: {
        'Authorization': `Bearer ${sessionStorage.getItem('DonFelipeToken')}`,
      },
    })
  }

  const updateCashLedger = (entryId, updates) => {
    return axios.post(`${config.baseUrl}/reports/cashLedger/${entryId}`, updates, {
      headers: {
        'Authorization': `Bearer ${sessionStorage.getItem('DonFelipeToken')}`,
      },
    })
  }

  const updateMonthlyValue = (updates) => {
    return axios.post(`${config.baseUrl}/reports/setMonthValue/`, updates, {
      headers: {
        'Authorization': `Bearer ${sessionStorage.getItem('DonFelipeToken')}`,
      },
    })
  }

  const deleteCashLedger = entryID => {
    return axios.delete(`${config.baseUrl}/reports/cashLedger/delete/${entryID}`, {
      headers: {
        'Authorization': `Bearer ${sessionStorage.getItem('DonFelipeToken')}`,
      },
    })
  }

/* cashLedger */

export default { 
  getAnnualReport, 
  getForecastReport,
  updateMonthForecast,
  updateYearForecast,
  getMonthlyTotalByCompany, 
  getMonthlyTypeMoneyReport, 
  cashLedger,
  updateCashLedger,
  deleteCashLedger,
  getRetention,
  updateRetention,
  getSuplierConstructionReport,
  updateMonthlyValue
}
