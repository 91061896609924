import React, { Component } from 'react'
import { Link, Redirect } from 'react-router-dom'
import logo from '../../assets/logo-white.jpg';
import loaderIcon from '../../assets/loader.svg';
import PrimaryButton from '../../components/Layout/PrimaryButton'

import loginService from '../../services/login'
import authService from '../../services/auth'

import './Login.scss'

class Login extends Component {
  constructor(props) {
    super(props)

    this.state = {
      email: '',
      password: '',
      loggedInSuccessfully: false,
      hasErrors: false,
      loader: false
    }

    this.handleEmailChange = this.handleEmailChange.bind(this)
    this.handlePasswordChange = this.handlePasswordChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleEmailChange(e) {
    this.setState({ email: e.target.value })
  }

  handlePasswordChange(e) {
    this.setState({ password: e.target.value })
  }

  handleSubmit(e) {
    e.preventDefault()

    const params = {
      email: this.state.email,
      password: this.state.password,
    }
    this.setState({ loader: true })

    loginService.login(params).then(response => {
      sessionStorage.setItem('DonFelipeToken', response.data.token)
      sessionStorage.setItem('DonFelipeTokenM', response.data.tokenM)
      sessionStorage.setItem('DonFelipeAToken', response.data.aToken)
      sessionStorage.setItem('DonFelipeUsername', response.data.name)
      sessionStorage.setItem('DonFelipeUserId', response.data.userID)
      sessionStorage.setItem('DonFelipePermission', response.data.permission)
      this.setState({ loggedInSuccessfully: true })
    }).catch(error => {
      this.setState({ hasErrors: true, loader: false })
      console.log(error)
    }).then((response) => {
      this.setState({ loader: false })
    })
  }

  render() {
    const errorMessage = () => {
      if (this.state.hasErrors) {
        return <p className="login-screen__has-error" style={{color: 'red'}}>E-mail ou senha inválidos</p>
      } else {
        return null
      }
    }

    if (this.state.loggedInSuccessfully || authService.isLoggedIn()) {
      const permission = parseInt(sessionStorage.getItem('DonFelipePermission'), 10);

      if (permission === 1) {
        return <Redirect to='/pagamento-conciliacao' />;
      } else if (permission === 2) {
        return <Redirect to='/relatorios' />;
      } if (permission === 3) {
        return <Redirect to='/pagamento-conciliacao' />;
      }if (permission === 4) {
        return <Redirect to='/pagamento-conciliacao' />;
      }
      else {
        return <Redirect to='/pagamento-conciliacao' />;
      }
    } else {
      return (
        this.state.loader ? (
          <div className='login-screen'>
            <img src={logo} className="App-logo" alt="logo" />
            <img src={loaderIcon} alt="Carregando..." style={{width: '100px', height: '100px', margin: '0 auto' }}/>
          </div>
        ) : (
        <div className='login-screen'>
          <img src={logo} className="App-logo" alt="logo" />
          <form className="login-screen__form" action="#">
            {errorMessage()}
            <input
                className="login-screen__input"
                type="text"
                placeholder="E-mail"
                onChange={this.handleEmailChange}
                value={this.state.email}
            />
            <input
                className="login-screen__input"
                type="password"
                placeholder="Senha"
                onChange={this.handlePasswordChange}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    this.handleSubmit(e)
                  }
                }}
                value={this.state.password}
            />
            <PrimaryButton fullWidth onClick={this.handleSubmit}>Entrar</PrimaryButton>
            <Link to='/forgotPassword' className="login-screen__link">Esqueci minha senha</Link>
          </form>
        </div>
        )
      )
    }
  }
}

export default Login;
