import React, { useState, useEffect, useCallback } from 'react';
import debounce from 'lodash/debounce';
import reportsService from '../../../services/reports';
import loaderIcon from '../../../assets/loader.svg';
import Utils from '../../../components/Utils/Utils';
import ScrollableTable from '../../../components/DataGrid/DataGrid';

import ModalLedger from '../../../components/ModalLedger';

function CashLedger({ selectedDate, totalPaidMoney }) {
  const [loader, setLoader] = useState(false);
  const [cashLedgerReport, setCashLedgerReport] = useState(null);
  const [modalExpenseLedger, setModalExpenseLedger] = useState(false);
  const [finalBalance, setFinalBalance] = useState(0);

  const debouncedUpdateExpense = useCallback(
    debounce((value, entryID, name) => {
      setCashLedgerReport((prevReport) => {
        const updatedReport = prevReport.map((row) => {
          if (row.entryID === entryID) {
            return {
              ...row,
              [name]: value,
            };
          }
          return row;
        });
        setLoader(true);
        updateCashLedger(updatedReport);
        return updatedReport;
      });
    }, 1500),
    []
  );

  const handleCloseModal = (e) => {
    setModalExpenseLedger(false);
  }

  const handModalSubmit = (data) => {
    updateCashLedger([data]);
  }

  const updateCashLedger = async (updatedFields) => {
    setLoader(true);
    try {
      if (updatedFields !== null) {
        delete updatedFields.addButton;
        for (const row of updatedFields) {
          if (row.entryID !== 14) {
            await reportsService.updateCashLedger(row.entryID, row);
          }
        }

        const result = await reportsService.cashLedger(`${selectedDate}-01`);
        if (Array.isArray(result.data)) {
          setCashLedgerReport(sortCashLedgerReport(result.data));
        } else {
          console.error('Invalid data structure for cashLedgerReport after update:', result);
        }
      }
    } catch (error) {
      console.error('Error updating cash ledger:', error);
    } finally {
      setLoader(false);
    }
  };

  const sortCashLedgerReport = (report) => {
    return report.sort((a, b) => (a.entryID === 14 ? -1 : b.entryID === 14 ? 1 : 0));
  };

  useEffect(() => {
    const fetchForecastReport = async () => {
      try {
        if (typeof selectedDate !== 'object' && selectedDate !== null) {
          setCashLedgerReport([]);
          setLoader(true);
          const result = await reportsService.cashLedger(`${selectedDate}`);
          if (Array.isArray(result.data)) {
            setCashLedgerReport(sortCashLedgerReport(result.data));
          } else {
            console.error('Invalid data structure for cashLedgerReport:', result);
          }
        }
      } catch (error) {
        console.error('Error fetching expenses:', error);
      } finally {
        setLoader(false);
      }
    };

    fetchForecastReport();
  }, [selectedDate]);

  const handleAddItems = (index, id) => {
    setModalExpenseLedger(true);
  };

  const handleRemoveItems = async (index, entryID) => {
    try {
      await reportsService.deleteCashLedger(entryID);
      setCashLedgerReport((prevReport) => prevReport.filter((item) => item.entryID !== entryID));
    } catch (error) {
      console.error('Error removing cash ledger item:', error);
    }
  };

  const dynamicColumns = [
    {
      Header: 'Data',
      accessor: 'entryDate',
      width: 80,
      textAlign: 'left',
    },
    {
      Header: 'Descrição',
      accessor: 'description',
      width: 150,
      textAlign: 'left',
    },
    {
      Header: 'Valor',
      accessor: 'incomingCash',
      width: 80,
      textAlign: 'left',
    },
    {
      Header: '',
      accessor: 'addButton',
      width: 40,
      textAlign: 'left',
    },
  ];

  const rows = cashLedgerReport ? 
  [
    {
      entryDate: <strong>{selectedDate.split('-')[1]}/{selectedDate.split('-')[0]}</strong>,
      description: <strong>Saldo Inicial</strong>,
      incomingCash: <strong>{cashLedgerReport[0]?.monthAmount ? Utils.currencyFormat(cashLedgerReport[0]?.monthAmount) :  Utils.currencyFormat(0)}</strong>,
      addButton: 
        <>
          <button
            className="btn-add-more"
            style={{ display: 'inline-block', marginRight: '10px' }}
            onClick={() => handleAddItems(0)}  // Assuming handleAddItems accepts an index
          >
            +
          </button>
        </>
    },
    ...cashLedgerReport.map((item, index) => ({
      entryDate: item.entryDate ? `${item.entryDate.split('-')[2]}/${item.entryDate.split('-')[1]}/${item.entryDate.split('-')[0]}` : '',
      description: item.description,
      incomingCash: <span className={item.incomingCash>=0 ? 'blue':'red' }>{item.incomingCash && Utils.currencyFormat(item.incomingCash)}</span>,
      addButton: item.entryDate &&
        <>
          <button
            className="btn-add-more"
            style={{ display: 'inline-block' }}
            onClick={() => handleRemoveItems(index, item.entryID)}
          >
            -
          </button>
        </>
    }))
  ]
  : [];
      

  const calculateTotalDebits = (report) => {
    const totalDebits = report.reduce((total, entry) => {
      return entry.entryID !== 14 && entry.incomingCash < 0 ? total + entry.incomingCash : total;
    }, 0);
    return totalDebits;
  };
  
  const calculateTotalCredits = (report) => {
    return report.reduce((total, entry) => {
      return entry.entryID !== 14 && entry.incomingCash > 0 ? total + entry.incomingCash : total;
    }, 0);
  };
  
  function getNextMonthDate(year, month) {
    let nextYear = year;
    let nextMonth = month + 1;
  
    if (nextMonth > 12) {
      nextMonth = 1;
      nextYear += 1;
    }
  
    return { nextYear, nextMonth };
  }
  
  const calculateFinalBalance = async (report) => {
    const monthAmount = report[0]?.monthAmount || 0;
    const totalCredits = await calculateTotalCredits(report);
    const totalDebits = await calculateTotalDebits(report);
    const totalMonth = monthAmount + totalCredits + totalDebits;
    return totalMonth;
  };
  
  useEffect(() => {
    const updateMonthlyValue = async () => {
      if (!cashLedgerReport) return; // Add a check to handle null or undefined cashLedgerReport
  
      const currentDate = selectedDate.split('-');
      const { nextYear, nextMonth } = getNextMonthDate(parseInt(currentDate[0], 10), parseInt(currentDate[1], 10));
      const finalBalance = await calculateFinalBalance(cashLedgerReport);
      setFinalBalance(finalBalance);
  
      await reportsService.updateMonthlyValue({
        month: nextMonth,
        year: nextYear,
        value: finalBalance
      });
    };
  
    updateMonthlyValue();
  }, [cashLedgerReport]); // useEffect dependency should be cashLedgerReport
  
  const tableFooter = cashLedgerReport && (
    <>
      <tr className='tfoot'>
        <td><strong>{new Date(`${selectedDate}-1`).toLocaleDateString('pt-BR').substring(3)}</strong></td>
        <td>
          <strong>Saída mês vigente</strong>
        </td>
        <td colSpan={2}>
          <strong className='red'>{Utils.currencyFormat(totalPaidMoney)}</strong>
        </td>
      </tr>
      <tr className='tfoot'>
        <td>
          <strong>Total:</strong>
        </td>
        <td>
          <strong>Saldo Final</strong>
        </td>
        <td colSpan={2}>
          <strong>{Utils.currencyFormat(finalBalance - totalPaidMoney)}</strong>
        </td>
      </tr>
    </>
  );

  return (
    <>
      {
        modalExpenseLedger && (
          <ModalLedger onCloseModal={ handleCloseModal } onFormSubmit={handModalSubmit} />
        )
      }
      <div className="wrapper-report report-ledger" style={{ marginTop: '20px' }}>
        {loader ? (
          <img src={loaderIcon} alt="Carregando..." width={100} height={100} />
        ) : (
          <ScrollableTable headers={dynamicColumns} height="450px" dataRows={rows} footer={tableFooter} />
        )}
      </div>
    </>
  );
}

export default CashLedger;
