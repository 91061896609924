import React, { Component } from "react";
import { Route, Switch, withRouter } from "react-router-dom";

import './App.scss';
import Header from './components/Header';
import LoginScreen from "./views/Login";
import ForgotPassword from "./views/ForgotPassword";
import ForgotPasswordSuccess from "./views/ForgotPasswordSuccess";
import ChangePassword from "./views/ChangePassword";
import ChangePasswordSuccess from "./views/ChangePasswordSuccess";
import Supplier from "./views/Supplier";
import Expenses from "./views/Expenses";
import Conciliation from "./views/Conciliation";
import Reports from "./views/Reports";
import Appopriations from "./views/Appropriations/Appropriations";
import Constructions from "./views/Constructions/Constructions";
import Companies from "./views/Companies/Companies";

class App extends Component {
  render() {
    const permission = parseInt(sessionStorage.getItem('DonFelipePermission'), 10);

    return (
      <div className="App">
        <Header username={sessionStorage.getItem('DonFelipeUsername')} />
        <Switch>
          {permission <= 2 && (
            <>
              <Route exact path="/cadastro-fornecedor" component={Supplier} />
              <Route exact path="/cadastro-empresas" component={Companies} />
              <Route exact path="/cadastro-apropriacao" component={Appopriations} />
              <Route exact path="/cadastro-obracc" component={Constructions} />
              <Route exact path="/cadastro-despesas" component={Expenses} />
              <Route exact path="/pagamento-conciliacao" component={Conciliation} />
              <Route exact path="/relatorios" component={Reports} />
              <Route exact path="/change-password" component={ChangePassword} />
              <Route exact path="/change-password-success" component={ChangePasswordSuccess} />
            </>
          )}
          {permission === 3 && (
            <>
              <Route exact path="/cadastro-fornecedor" component={Supplier} />
              <Route exact path="/cadastro-despesas" component={Expenses} />
              <Route exact path="/pagamento-conciliacao" component={Conciliation} />
            </>
          )}
          {permission === 4 && (
            <>
              <Route exact path="/pagamento-conciliacao" component={Conciliation} />
            </>
          )}
          <Route exact path="/forgotPassword" component={ForgotPassword} />
          <Route exact path="/forgotPasswordSuccess" component={ForgotPasswordSuccess} />
          <Route path="/" component={LoginScreen} />
        </Switch>
      </div>
    );
  }
}

export default withRouter(App);
