import React, { useState } from 'react';
import suppliersService from '../../services/suppliers';
import loaderIcon from '../../assets/loader.svg';
import PrimaryButton from '../../components/Layout/PrimaryButton';
import MaskInput from '../../components/Layout/MaskInput';
import editIcon from '../../assets/edit-icon.png';

import ModalSuppliers from '../../components/ModalSuppliers';

import Utils from '../../components/Utils/Utils';

import './Supplier.scss';

const Supplier = () => {
  const initialFields = {
    name: { value: '', valid: true, validationMessage: '' },
    tradingName: { value: '', valid: true, validationMessage: '' },
    cnpj: { value: '', valid: true, validationMessage: '' },
    phone: { value: '', valid: true, validationMessage: '' },
    contactTitle: { value: '', valid: true, validationMessage: '' },
    comments: { value: '', valid: true, validationMessage: '' },
  };

  const [fields, setFields] = useState({ ...initialFields });
  const [loader, setLoader] = useState(false);
  const [backendError, setBackendError] = useState('');
  const [successForm, setSuccessForm] = useState(false);
  const [suppliersList, setSuppliersList] = useState(false);
  const [editSupplier, setEditSupplier] = useState(false);

  const handleInputChange = (e) => {
    if (e.type !== 'change') {
      const fieldName = e.type;
      const formattedValue = e.formattedValue;
      setFields((prevFields) => ({
        ...prevFields,
        [fieldName]: {
          ...prevFields[fieldName],
          value: formattedValue,
        },
      }));
    } else {
      const target = e.target;
      const value = target.type === 'checkbox' ? target.checked : target.value;
      const name = target.name;
      setFields((prevFields) => ({
        ...prevFields,
        [name]: {
          ...prevFields[name],
          value: value,
        },
      }));
    }
  };

  const saveSupplier = async (supplierData) => {
    try {
      if (!editSupplier) {
        const result = await suppliersService.createSupplier(supplierData);
      } else {
        await suppliersService.updateSupplier(supplierData.supplierID, supplierData);
      }
      setSuccessForm(true);
      setBackendError('');
    } catch (error) {
      console.error('Error fetching expenses:', error);
      if (error.response && error.response.data && error.response.data.message === "CNPJ já existe no sistema.") {
        // Handle specific error message for existing CNPJ
        setBackendError('Fornecedor não cadastrado. CNPJ já existe!');
      } else {
        // Handle other errors
        setBackendError('Algo deu errado! Tente novamente.');
      }
    } finally {
      setLoader(false);
    }
  };

  const handleCloseModal = (e) => {
    setSuppliersList(false);
  }

  const handleSupplierChange = async (data) => {
    setFields((prevFields) => ({
      comments: {
        ...prevFields['comments'],
        value: data.comments,
      },
      contactTitle: {
        ...prevFields['contactTitle'],
        value: data.contactTitle,
      },
      name: {
        ...prevFields['name'],
        value: data.name,
      },
      cnpj: {
        ...prevFields['cnpj'],
        value: data.cnpj,
      },
      phone: {
        ...prevFields['phone'],
        value: data.phone,
      },
      tradingName: {
        ...prevFields['tradingName'],
        value: data.tradingName,
      },
      supplierID: {
        ...prevFields['supplierID'],
        value: data.supplierID,
      },
    }));
    setSuppliersList(false);
    setEditSupplier(true);
  }

  const validateCNPJ = (cnpj) => {
    return Utils.isValidCNPJ(cnpj);
  };

  const validateCompanyName = (name) => {
    return name.trim() !== '';
  };

  const validateForm = () => {
    // Validation for Razão Social and CNPJ
    const isNameValid = validateCompanyName(fields.name.value);
    const isCnpjValid = validateCNPJ(fields.cnpj.value);

    setFields((prevFields) => ({
      ...prevFields,
      ['cnpj']: {
        ...prevFields['cnpj'],
        valid: Utils.isValidCNPJ(fields.cnpj.value),
      },
      ['name']: {
        ...prevFields['name'],
        valid: fields.name.value.length > 2,
      },
    }));

    if (!isNameValid || !isCnpjValid) {
      setLoader(false);
      return false;
    } else {
      setBackendError('');
    }

    return true;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    setLoader(true);
    const formData = { isActive: true };
    Object.entries(fields).forEach(([fieldName, fieldData]) => {
      formData[fieldName] = fieldData.value;
    });

    try {
      await saveSupplier(formData);
      setSuccessForm(true);
    } catch (error) {
      console.error('Error saving supplier:', error);
      setBackendError('Algo deu errado! Tente novamente.');
    } finally {
      setLoader(false);
    }
  };

  const resetForm = () => {
    setFields({ ...initialFields });
    setLoader(false);
    setBackendError('');
    setSuccessForm(false);
    setEditSupplier(false);
  };

  return (
    <>
      {suppliersList && (
        <ModalSuppliers onCloseModal={handleCloseModal} supplierData={handleSupplierChange} />
      )}

      <div className='supplier-screen wrapper-content form-box'>
        {!backendError && !successForm && (
          <>
            {editSupplier && (
              <button className="back-button" onClick={(e) => { e.preventDefault(); setLoader(true); window.location.reload(); }} style={{ background: 'none', border: 'none', cursor: 'pointer', position: 'absolute', left: 10, top: 15 }}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M15 18L9 12L15 6" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </button>
            )}
            <h2>{!editSupplier ? 'Cadastro de Fornecedor' : 'Editar Fornecedor'}</h2>
            <button className='editSup' onClick={() => setSuppliersList(true)}><img src={editIcon} alt="Edit icon" style={{ width: '18px', height: '18px' }} /></button>
          </>
        )}

        {backendError && (
          <>
            <h2 className="error-message">
              {backendError}
            </h2>
            <PrimaryButton fullWidth onClick={resetForm}>
              Voltar
            </PrimaryButton>
          </>
        )}

        {!backendError && successForm && (
          <div style={{ width: '60%', margin: '0 auto' }}>
            <h2>{!editSupplier ? 'Cadastro realizado no sistema!' : 'Edição realizada com sucesso!'}</h2>
            <PrimaryButton fullWidth onClick={resetForm}>
              Voltar
            </PrimaryButton>
          </div>
        )}

        {loader && !backendError && !successForm ? (
          <img src={loaderIcon} alt="Carregando..." width={100} height={100} />
        ) : (
          !successForm && (
            <form onSubmit={handleSubmit} className="supplier-screen__form" action="#">
              <div className='wrapper-columns'>
                <div className='column'>
                  <label>
                    Razão Social<sup>*</sup>
                    <input
                      type="text"
                      name='name'
                      className={fields.name.valid === false ? 'has-error' : ''}
                      onChange={handleInputChange}
                      defaultValue={fields.name.value}
                      tabIndex={1}
                      onBlur={(e) => {
                        validateForm();
                      }}
                    />
                  </label>
                  <label>
                    CNPJ<sup>*</sup>
                    <MaskInput
                      type="cnpj"
                      tabIndex={3}
                      defaultValue={fields.cnpj.value}
                      className={fields.cnpj.valid === false ? 'has-error' : ''}
                      onValueChange={handleInputChange}
                      onBlur={(value) => {
                        validateForm();
                      }}
                    />
                  </label>
                </div>
                <div className='column'>
                  <label>
                    Nome Fantasia<sup></sup>
                    <input
                      type="text"
                      name='tradingName'
                      onChange={handleInputChange}
                      defaultValue={fields.tradingName.value}
                      tabIndex={2}
                    />
                  </label>
                  <label>
                    Telefone<sup></sup>
                    <MaskInput
                      type="phone"
                      tabIndex={4}
                      defaultValue={fields.phone.value}
                      onValueChange={handleInputChange}
                    />
                  </label>
                </div>
              </div>
              <label>
                Nome do Contato
                <input
                  type="text"
                  name='contactTitle'
                  onChange={handleInputChange}
                  defaultValue={fields.contactTitle.value}
                  tabIndex={5}
                />
              </label>

              <label>
                Observação
                <textarea
                  name='comments'
                  onChange={handleInputChange}
                  defaultValue={fields.comments.value}
                  tabIndex={6}
                />
              </label>
              <input className={'btn--primary'} type='submit' value={!editSupplier ? "Salvar" : "Salvar Edição"} tabIndex={7} />
            </form>
          )
        )}
      </div>
    </>
  );
};

export default Supplier;
