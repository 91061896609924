import axios from 'axios'
import config from '../config'

const getAll = () => {
  return axios.get(`${config.baseUrl}/appropriation`, {
    headers: {
      'Authorization': `Bearer ${ sessionStorage.getItem('DonFelipeToken') }`,
    },
  })
}

const getById = id => {
  return axios.get(`${config.baseUrl}/appropriation/${id}`, {
    headers: {
      'Authorization': `Bearer ${sessionStorage.getItem('DonFelipeToken')}`,
    },
  })
}

const createAppropriation = supplier => {
  return axios.post(`${config.baseUrl}/appropriation`, supplier, {
    headers: {
      'Authorization': `Bearer ${sessionStorage.getItem('DonFelipeToken')}`,
    },
  })
}

const updateAppropriation = (supplierId, updates) => {
  return axios.put(`${config.baseUrl}/appropriation/${supplierId}`, updates, {
    headers: {
      'Authorization': `Bearer ${sessionStorage.getItem('DonFelipeToken')}`,
    },
  })
}

export default { getAll, getById, createAppropriation, updateAppropriation }
