import React, { useEffect, useState, useRef } from 'react';

import expensesService from '../../services/expenses';
import suppliersService from '../../services/suppliers';
import companiesService from '../../services/companies';
import constructionsService from '../../services/constructions';
import appropriationsService from '../../services/appropriation';

import Utils from '../../components/Utils/Utils';

import FilterBar from '../../components/FilterBar';
import DatePagination from '../../components/DatePagination';
import ScrollableTable from '../../components/DataGrid/DataGrid';
import TableHeaders from '../../views/Conciliation/table-headers';
import MaskInput from '../../components/Layout/MaskInput';

import invoiceIcon from '../../assets/invoice-icon.png';
import printIcon from '../../assets/print-icon.svg';
import loaderIcon from '../../assets/loader.svg';
import './Conciliation.scss';

import ModalPDF from '../../components/ModalPDF';
import ModalAttach from '../../components/ModalAttach';

function Conciliation() {
  useEffect(() => {
    if (!sessionStorage.getItem('hasReloaded')) {
      sessionStorage.setItem('hasReloaded', 'true');
      window.location.reload();
    }
  }, []);

  const [fields, setFields] = useState({});
  const [expenses, setExpenses] = useState([]);
  const pendingUpdates = useRef(new Set()); // Track pending updates
  const [rowLoading, setRowLoading] = useState({});

  const [suppliers, setSuppliers] = useState([]);
  const [constructions, setConstructions] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [selectedDate, setSelectedDate] = useState({});
  const [getRequestComp, setGetRequestComp] = useState(0);

  const [loader, setLoader] = useState(false);
  const [modalPDF, setModalPDF] = useState({status: false, url: ''});
  const [modalAttach, setModalAttach] = useState({status: false, code: ''});
  
  const [typeSearchString, setTypeSearchString] = useState('');
  
  const [paymentApproachList, setPaymentApproachList] = useState([{ id: 1, name: 'Cheque' }, { id: 2, name: 'Dinheiro' }, { id: 3, name: 'Internet' }, { id: 4, name: 'Débito C.C.' } ]);
  const [appropriationList, setAppropriation] = useState([{ id: 1, name: 'Fundação' }, { id: 2, name: 'Alvenaria' }, { id: 3, name: 'Esquadrias' }, { id: 3, name: 'Pintura' }, { id: 3, name: 'Telhado' }]);

  const [orderBy, setOrderBy] = useState([['dueDate', 'ASC']]);
  const [deleteModal, setDeleteModal] = useState({ status: false, deleteExpID: null, paid: false });
  const [highlightedRow, setHighlightedRow] = useState(null);

  const dynamicFieldsCheck = (expense) => {
    const dynamicFields = {};
    expense.forEach((expenseItem) => {
      dynamicFields[`expenseID${expenseItem.expenseID}`] = {
        expenseDate: { value: expenseItem.expenseDate.replace('--', '-0'), valid: true, validationMessage: '' },
        pdfPath: { value: expenseItem.pdfPath, valid: true, validationMessage: '' },
        billPath: { value: '-', valid: true, validationMessage: '' },
        
        companyID: { value: expenseItem.companyID, valid: true, validationMessage: '' },
        constructionID: { value: expenseItem.constructionID, valid: true, validationMessage: '' },
        supplierID: { value: expenseItem.supplierID, valid: true, validationMessage: '' },

        invoiceID: { value: expenseItem.invoiceID, valid: null, validationMessage: '' },
        dueDate: { value: expenseItem.dueDate, valid: null, validationMessage: '' },
        amount: { value: expenseItem.amount, valid: null, validationMessage: '' },
        comments: { value: expenseItem.comments, valid: true, validationMessage: '' },
        installment: { value: expenseItem.installment, valid: null, validationMessage: '' },
        buyer: { value: expenseItem.user.name, valid: true, validationMessage: '' },
        retention: { value: expenseItem.retention, valid: true, validationMessage: '' },
        paymentDate: { value: expenseItem.paymentDate, valid: true, validationMessage: '' },
        appropriation: { value: expenseItem.appropriation, valid: true, validationMessage: '' },
        payment_approach: { value: !expenseItem.payment_approach ? 'Internet' : expenseItem.payment_approach, valid: true, validationMessage: '' },
        paid: { value: expenseItem.paid, valid: false, validationMessage: '' },
        conciliation: { value: expenseItem.conciliation, valid: true, validationMessage: '' },
        accounting: { value: expenseItem.accounting, valid: true, validationMessage: '' },
      };
    });

    // Update initialFields with dynamicFields
    setFields((prevFields) => ({
      ...prevFields,
      ...dynamicFields,
    }));
  }

  const permission = parseInt(sessionStorage.getItem('DonFelipePermission'), 10);

  const handleDeleteClick = (expenseID, paid) => {
    setHighlightedRow(expenseID);
    setDeleteModal({ status: true, deleteExpID: expenseID, paid: paid });
  };
  
  const handleCloseModal = (e) => {
    setModalPDF({ status: false, url: '' });
    setModalAttach({ status: false, code: 0 });
  }

  function getRandomInt(min, max) {
    return Math.floor(Math.random() * (max - min) + min);
  }

  useEffect(() => {
    const getSuppliers = async () => {
      try {
        const supplier = await suppliersService.getAll();
        setSuppliers(supplier.data);
      } catch (error) {
        console.error('Error fetching expenses:', error);
      }
    };

    const getCompanies = async () => {
      try {
        const company = await companiesService.getAll();
        setCompanies(company.data);
      } catch (error) {
        console.error('Error fetching expenses:', error);
      }
    };

    const getConstructions = async () => {
      try {
        const construction = await constructionsService.getAll();
        setConstructions(construction.data);
      } catch (error) {
        console.error('Error fetching expenses:', error);
      }
    };

    const getAppropriation = async () => {
      try {
        const appropriationData = await appropriationsService.getAll();
        setAppropriation(appropriationData.data);
      } catch (error) {
        console.error('Error fetching expenses:', error);
      }
    };
    
    getAppropriation();
    getSuppliers();
    getCompanies();
    getConstructions();
  }, []);

  useEffect(() => {
    const timer = setTimeout(async () => {
      if (pendingUpdates.current.size > 0) {
        for (const expenseID of pendingUpdates.current) {
          const updatedFields = fields[`expenseID${expenseID}`];
          
          if (updatedFields) {
            const values = {
              expenseID,
              ...Object.fromEntries(
                Object.entries(updatedFields).map(([key, value]) => [key, value.value])
              ),
            };
  
            try {
              setRowLoading((prev) => ({ ...prev, [expenseID]: true }));
              await expensesService.updateExpense(expenseID, values);
              setRowLoading((prev) => ({ ...prev, [expenseID]: false }));
            } catch (error) {
              console.error('Error updating expense:', error);
            }
          }
        }
  
        pendingUpdates.current.clear();
        setLoader(false);
      }
    }, 2000); // Adjust the delay as needed
  
    return () => clearTimeout(timer);
  }, [fields]);  

  const handleFilterExpenses = (filteredExpenses) => {
    setExpenses([]);
    setLoader(true);
    setTimeout(() => {
      dynamicFieldsCheck(filteredExpenses);
      setExpenses(filteredExpenses);
      setLoader(false);
    }, 0)
  };

  useEffect(() => {
    return () => {
      pendingUpdates.current.clear();
    };
  }, []);

  /* Handles */
    const handleSelectChange = async (e, expenseID, param) => {
      const newValue = e.target.value;

      if(newValue) {
        setFields((prevFields) => ({
          ...prevFields,
          [`expenseID${expenseID}`]: {
            ...prevFields[`expenseID${expenseID}`],
            [param]: {
              ...prevFields[`expenseID${expenseID}`]?.[param],
              value: newValue,
            },
          },
        }));

        pendingUpdates.current.add(expenseID);
      }
    };

    const handleCompetenceChange = async (e, expenseID) => {
      const newMonth = e.target.value.split('/');
      const expenseDate = fields[`expenseID${expenseID}`]['expenseDate'].value;
      const updatedDate = `${newMonth[1]}-${newMonth[0]}-${expenseDate.substr(expenseDate.length - 2, expenseDate.length)}`;

      setFields((prevFields) => ({
        ...prevFields,
        [`expenseID${expenseID}`]: {
          ...prevFields[`expenseID${expenseID}`],
          ['expenseDate']: {
            ...prevFields[`expenseID${expenseID}`]?.['expenseDate'],
            value: updatedDate,
          },
        },
      }));
      setLoader(true);
      setTimeout(() => {
        setGetRequestComp(getRandomInt(1, 1000));
      }, 500)
      pendingUpdates.current.add(expenseID);
      // setTimeout(() => {
      //   window.location.reload();
      // }, 1000);
      // processTrialMtDB(expenseID, updatedDate);
    };

    const processTrialMtDB = async (expenseID, updatedDate) => {
      console.log('updatedDat: ', updatedDate.substring(0, 7));
      const mtId = await constructions.find(item => item.constructionID === fields[`expenseID${expenseID}`]?.constructionID.value).mtId;
      const trialIdByCustomer =  await expensesService.getTrialMonthByCustomer(mtId, updatedDate.substring(0, 7));

      console.log('mtId: ', mtId);
      console.log('updatedDat: ', updatedDate.substring(0, 7));
      console.log('trialIdByCustomer: ', trialIdByCustomer);
      const fileUrl = fields[`expenseID${expenseID}`].pdfPath.value;
      const documentCode = fileUrl.split('/').pop().replace('.pdf', '');
      if(documentCode.length) {
        if(!trialIdByCustomer.data.length) {
          const trialData = {
            "customerId": mtId,
            "name": updatedDate.substring(0, 7),
            "status": "private",
            "description": `Processo criado na empresa - ${companies.find(item => item.companyID === fields[`expenseID${expenseID}`]['companyID'].value)?.name}`,
            "users": [330],
            "historic": "{0} criou o processo."
          };

          expensesService.addTrialMtDB(trialData)
          .then(async (response) => {
            console.log('trialId: ', response.data.id);
            console.log('documentCode: ', documentCode);
            expensesService.editTrialMtDB({
              documentCode: documentCode,
              trialId: response.data.id
            }).then(response => {
              console.log("Success:", response);
            })
            .catch(error => {
              console.error("Error:", error); // Log the full error details
            });

            // const dataToMd = {
            //   code: documentCode, // O código do arquivo
            //   url: fileUrl,   // A URL do arquivo retornado
            //   trialId: response.data.id,
            //   name: suppliers.find(item => item.supplierID === fields.supplierID.value.value)?.name,
            //   physicalStorageLocation: 'Pendente',
            //   date: updatedDate,
            //   observation: `Documento ${documentCode} adicionado pelo sistema Don Felipe.`,
            // };
            // const expense = await expensesService.addDocumentMtDB(dataToMd);
          });
        } else {
          console.log('exist trial', trialIdByCustomer.data[0]);
          expensesService.editTrialMtDB({
            documentCode: documentCode,
            trialId: trialIdByCustomer.data[0].id
          });
        }
      }
    }
 
    const handleInputChange = (e, expenseID) => {
      let name = '';
      let value = '';
      if (e.type !== 'change') {
        name = e.type;
        value = e.formattedValue;
      } else {
        value = e.target.value;
        name = e.target.name;
      }

      if (name === 'amount') {
        value = parseFloat(value.replace('R$', '').replace('.', '').replace(',', '.').trim());
      }

      setFields((prevFields) => ({
        ...prevFields,
        [`expenseID${expenseID}`]: {
          ...prevFields[`expenseID${expenseID}`],
          [name]: {
            ...prevFields[`expenseID${expenseID}`]?.[name],
            value: value,
          },
        },
      }));

      pendingUpdates.current.add(expenseID);
    };

    const handleCheckboxChange = (e, expenseID, param) => {
      const brazilianDate = new Date(new Date().toLocaleString("en-US", { timeZone: "America/Sao_Paulo" }));
      const year = brazilianDate.getFullYear();
      const month = String(brazilianDate.getMonth() + 1).padStart(2, '0');
      const day = String(brazilianDate.getDate()).padStart(2, '0');
      const formattedDate = `${year}-${month}-${day}`;

      const { checked } = e.target;
      setFields((prevFields) => ({
        ...prevFields,
        [`expenseID${expenseID}`]: {
          ...prevFields[`expenseID${expenseID}`],
          [param]: {
            ...prevFields[`expenseID${expenseID}`]?.[param],
            value: checked,
          },
          ...(param === 'paid' && checked ?  {
            'paymentDate': {
              value: formattedDate
            }
          } : param === 'paid' && !checked ? {
            'paymentDate': {
              value: null
            },
            'conciliation': {
              value: false
            }
          } : {})
        },
      }));
  
      pendingUpdates.current.add(expenseID);
    };

    const handleUrlUpload = (url, expenseID) => {
      setFields((prevFields) => ({
        ...prevFields,
        [`expenseID${expenseID}`]: {
          ...prevFields[`expenseID${expenseID}`],
          pdfPath: { value: url },
        },
      }));
  
      pendingUpdates.current.add(expenseID);
    };
  /* // Handles */

  const renderSelectOptions = (data, keyField, displayField, selectedValue) => (
    <>
      <option key={0} value={0} className='no-print'>Selecione</option>
      {data.map((item, index) => (
        <option key={index} value={item[keyField]} defaultValue={item[keyField] === selectedValue}>
          {item[displayField]}
        </option>
      ))}
    </>
  );

  const handleDateChange = (date) => {
    if(typeof date !== 'object' && date !== null) {
      setSelectedDate(date);
      setTypeSearchString(date);
    }
  };

  function generateMonthOptions(expenseID) {
    const currentDate = fields[`expenseID${expenseID}`]?.['expenseDate'].value ? new Date(fields[`expenseID${expenseID}`]?.['expenseDate'].value) : new Date();
    const options = Array.from({ length: 3 }, (_, index) => {
      const date = new Date(currentDate.getFullYear(), currentDate.getMonth() + index - 1);
      const formattedOption = `${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getFullYear()}`;
      return formattedOption;
    });
  
    return options;
  }
  let competencePrint=generateMonthOptions('expense.expenseID')

  const handlePrintClick = () => {
    window.print();
  };

  let totalExpenses = 0;
  let rows = [];
  if(permission !== 4) {
    rows = expenses.map((expense, index) => {
      totalExpenses = totalExpenses + expense.amount;
      const dueDate = new Date(fields[`expenseID${expense.expenseID}`]?.dueDate.value);
      const paymentDate = new Date(fields[`expenseID${expense.expenseID}`]?.paymentDate.value);
      function formattedDate(data){
        return data.toLocaleDateString('pt-BR');
      }

      return {
        expenseID: expense.expenseID,
        companyName: 
        rowLoading[expense.expenseID] ? (
            <div className="row-loader">
                <img src={loaderIcon} alt="Loading..." width={20} height={20} />
            </div>
        ) : (
            <div className='wrapper-select'>
                <select
                    name='company'
                    defaultValue={fields[`expenseID${expense.expenseID}`]?.companyID.value}
                    onChange={(e) => handleSelectChange(e, expense.expenseID, 'companyID')}
                    disabled={(permission === 2) || (permission === 3 && fields[`expenseID${expense.expenseID}`]?.paid.value)}
                >
                    {renderSelectOptions(companies, 'companyID', 'name')}
                </select>
            </div>
        ),
        constrution: 
        rowLoading[expense.expenseID] ? (
            <div className="row-loader">
                <img src={loaderIcon} alt="Loading..." width={20} height={20} />
            </div>
        ) : (
            <div className='wrapper-select'>
                <select
                    name='construction'
                    defaultValue={fields[`expenseID${expense.expenseID}`]?.constructionID.value}
                    onChange={(e) => handleSelectChange(e, expense.expenseID, 'constructionID')}
                    disabled={(permission === 2) || (permission === 3 && fields[`expenseID${expense.expenseID}`]?.paid.value)}
                >
                    {renderSelectOptions(constructions, 'constructionID', 'name')}
                </select>
            </div>
        ),
        supplier: 
        rowLoading[expense.expenseID] ? (
            <div className="row-loader">
                <img src={loaderIcon} alt="Loading..." width={20} height={20} />
            </div>
        ) : (
            <div className='wrapper-select'>
              <select
                  name='supplier'
                  defaultValue={fields[`expenseID${expense.expenseID}`]?.supplierID.value}
                  onChange={(e) => handleSelectChange(e, expense.expenseID, 'supplierID')}
                  disabled={(permission === 2) || (permission === 3 && fields[`expenseID${expense.expenseID}`]?.paid.value)}
              >
                  {renderSelectOptions(suppliers, 'supplierID', 'name')}
              </select>
            </div>
        ),
        invoiceID: 
        rowLoading[expense.expenseID] ? (
            <div className="row-loader">
                <img src={loaderIcon} alt="Loading..." width={20} height={20} />
            </div>
        ) : (
            <>
                <input
                    type="text"
                    name="invoiceID"
                    onChange={(e) => handleInputChange(e, expense.expenseID)}
                    defaultValue={fields[`expenseID${expense.expenseID}`]?.invoiceID.value}
                    className='no-print'
                    disabled={(permission === 2) || (permission === 3 && fields[`expenseID${expense.expenseID}`]?.paid.value)}
                />
                <span className='print'>
                    {fields[`expenseID${expense.expenseID}`]?.invoiceID.value}
                </span>
            </>
        ),
        retention: 
        rowLoading[expense.expenseID] ? (
            <div className="row-loader">
                <img src={loaderIcon} alt="Loading..." width={20} height={20} />
            </div>
        ) : (
            <input
                type='checkbox'
                name={`retention-${expense.expenseID}`}
                checked={fields[`expenseID${expense.expenseID}`]?.retention.value || false}
                onChange={(e) => handleCheckboxChange(e, expense.expenseID, 'retention')}
                disabled={(permission === 2) || (permission === 3 && fields[`expenseID${expense.expenseID}`]?.paid.value)}
            />
        ),
        dueDate: 
        rowLoading[expense.expenseID] ? (
            <div className="row-loader">
                <img src={loaderIcon} alt="Loading..." width={20} height={20} />
            </div>
        ) : (
            <>
                <input
                    type='date'
                    name='dueDate'
                    onChange={(e) => handleInputChange(e, expense.expenseID)}
                    value={fields[`expenseID${expense.expenseID}`]?.dueDate.value}
                    disabled={(permission === 2) || (permission === 3 && fields[`expenseID${expense.expenseID}`]?.paid.value)}
                    className='no-print'
                />
                <span className='print'>
                    {formattedDate(dueDate)}
                </span>
            </>
        ),
        payment: 
        rowLoading[expense.expenseID] ? (
            <div className="row-loader">
                <img src={loaderIcon} alt="Loading..." width={20} height={20} />
            </div>
        ) : (
            <>
                <input
                    type='date'
                    name='paymentDate'
                    onChange={(e) => handleInputChange(e, expense.expenseID)}
                    value={fields[`expenseID${expense.expenseID}`]?.paymentDate.value}
                    disabled={(permission === 2) || (permission === 3 && fields[`expenseID${expense.expenseID}`]?.paid.value)}
                    className='no-print'
                />
                <span className='print'>
                    {formattedDate(paymentDate)}
                </span>
            </>
        ),
        payment_approach: 
        rowLoading[expense.expenseID] ? (
            <div className="row-loader">
                <img src={loaderIcon} alt="Loading..." width={20} height={20} />
            </div>
        ) : (
            <div className='wrapper-select'>
                <select
                    name='payment_approach'
                    defaultValue={fields[`expenseID${expense.expenseID}`]?.payment_approach.value ? fields[`expenseID${expense.expenseID}`]?.payment_approach.value : 'Internet'}
                    onChange={(e) => handleSelectChange(e, expense.expenseID, 'payment_approach')}
                    disabled={(permission === 2) || (permission === 3 && fields[`expenseID${expense.expenseID}`]?.paid.value)}
                >
                    {renderSelectOptions(paymentApproachList, 'payment_approach', 'name')}
                </select>
            </div>
        ),
        amount: 
        rowLoading[expense.expenseID] ? (
            <div className="row-loader">
              <img src={loaderIcon} alt="Loading..." width={20} height={20} />
            </div>
        ) : (
          (permission <= 3 && !fields[`expenseID${expense.expenseID}`]?.paid.value) || (permission === 1 && fields[`expenseID${expense.expenseID}`]?.paid.value) ? (
            <MaskInput
              type="amount"
              defaultValue={Utils.currencyFormat(fields[`expenseID${expense.expenseID}`]?.amount.value)}
              placeholder={"R$ 0,00"}
              onValueChange={(e) => handleInputChange(e, expense.expenseID)}
              tabIndex={8}
            />
          ) : (
            <input
              type='text'
              defaultValue={Utils.currencyFormat(fields[`expenseID${expense.expenseID}`]?.amount.value)}
              disabled={true}
              style={{ textAlign: 'right' }}
            />
          )
        ),
        comments: 
        rowLoading[expense.expenseID] ? (
            <div className="row-loader">
                <img src={loaderIcon} alt="Loading..." width={20} height={20} />
            </div>
        ) : (
            <input
                type='text'
                name='comments'
                onChange={(e) => handleInputChange(e, expense.expenseID)}
                defaultValue={fields[`expenseID${expense.expenseID}`]?.comments.value}
                disabled={(permission === 2) || (permission === 3 && fields[`expenseID${expense.expenseID}`]?.paid.value)}
            />
        ),
        invoice_bill: 
        rowLoading[expense.expenseID] ? (
            <div className="row-loader">
                <img src={loaderIcon} alt="Loading..." width={20} height={20} />
            </div>
        ) : (
            fields[`expenseID${expense.expenseID}`]?.pdfPath.value.indexOf('https') !== -1 && (
                <button
                    style={{ background: 'transparent', border: '0' }}
                    onClick={() => setModalPDF({ status: true, url: fields[`expenseID${expense.expenseID}`]?.pdfPath.value })}
                >
                    <img style={{ width: '35px' }} src={invoiceIcon} alt="Acessar documento." />
                </button>
            )
        ),
        attach: 
        rowLoading[expense.expenseID] ? (
            <div className="row-loader">
                <img src={loaderIcon} alt="Loading..." width={20} height={20} />
            </div>
        ) : (
          (permission === 1 || (permission === 3)) && 
          (
            <button
                className="btn-add-more"
                onClick={() => setModalAttach({
                    expenseID: expense.expenseID,
                    status: true,
                    mtId: constructions.find(item => item.constructionID === fields[`expenseID${expense.expenseID}`]?.constructionID.value).mtId,
                    dueDate: fields[`expenseID${expense.expenseID}`]?.dueDate.value,
                    companies: companies,
                    companyID: fields[`expenseID${expense.expenseID}`]?.companyID.value,
                    url: fields[`expenseID${expense.expenseID}`]?.pdfPath.value
                })}
                disabled={(permission === 2) && fields[`expenseID${expense.expenseID}`]?.paid.value}
            >
                +
            </button>
          )
        ),
        buyer: rowLoading[expense.expenseID] ? (
            <div className="row-loader">
                <img src={loaderIcon} alt="Loading..." width={20} height={20} />
            </div>
        ) : (
            fields[`expenseID${expense.expenseID}`]?.buyer.value
        ),
        paid: 
        rowLoading[expense.expenseID] ? (
            <div className="row-loader">
                <img src={loaderIcon} alt="Loading..." width={20} height={20} />
            </div>
        ) : (
            <input
                type='checkbox'
                name={`paid-${expense.expenseID}`}
                checked={fields[`expenseID${expense.expenseID}`]?.paid.value || false}
                onChange={(e) => handleCheckboxChange(e, expense.expenseID, 'paid')}
                disabled={(permission === 2) || (permission === 3 && fields[`expenseID${expense.expenseID}`]?.paid.value)}
            />
        ),
        conciliation: 
        rowLoading[expense.expenseID] ? (
            <div className="row-loader">
                <img src={loaderIcon} alt="Loading..." width={20} height={20} />
            </div>
        ) : (
            fields[`expenseID${expense.expenseID}`]?.paid?.value ? (
                <input
                    type='checkbox'
                    name={`conciliation-${expense.expenseID}`}
                    checked={fields[`expenseID${expense.expenseID}`]?.conciliation.value || false}
                    onChange={(e) => handleCheckboxChange(e, expense.expenseID, 'conciliation')}
                    disabled={(permission === 2) || (permission === 3 && fields[`expenseID${expense.expenseID}`]?.paid.value)}
                />
            ) : null
        ),
        appropriation: 
        rowLoading[expense.expenseID] ? (
            <div className="row-loader">
                <img src={loaderIcon} alt="Loading..." width={20} height={20} />
            </div>
        ) : (
            <div className='wrapper-select'>
                <select
                    name='appropriation'
                    defaultValue={fields[`expenseID${expense.expenseID}`]?.appropriation.value}
                    onChange={(e) => handleSelectChange(e, expense.expenseID, 'appropriation')}
                    disabled={permission === 3}
                >
                    {renderSelectOptions(appropriationList, 'appropriation', 'name')}
                </select>
            </div>
        ),
        accounting: 
        rowLoading[expense.expenseID] ? (
            <div className="row-loader">
                <img src={loaderIcon} alt="Loading..." width={20} height={20} />
            </div>
        ) : (
            <div className='wrapper-select'>
                <select
                    name='accounting'
                    defaultValue={expense.accounting ? fields[`expenseID${expense.expenseID}`]?.accounting.value : expense.constructionID}
                    onChange={(e) => handleSelectChange(e, expense.expenseID, 'accounting')}
                    disabled={(permission === 2) || (permission === 3 && fields[`expenseID${expense.expenseID}`]?.paid.value)}
                >
                    {renderSelectOptions(constructions, 'constructionID', 'name')}
                    <option value={'Não Contabilizado'} defaultValue={'Não Contabilizado'}>
                        Não Contabilizado
                    </option>
                </select>
            </div>
        ),
        competence: 
        rowLoading[expense.expenseID] ? (
            <div className="row-loader">
                <img src={loaderIcon} alt="Loading..." width={20} height={20} />
            </div>
        ) : (
            <div className='wrapper-select'>
                <span className='print'>
                    {competencePrint[1]}
                </span>
                <select
                    name='competence'
                    onChange={(e) => handleCompetenceChange(e, expense.expenseID, 'competence')}
                    disabled={(permission === 2) || (permission === 3 && fields[`expenseID${expense.expenseID}`]?.paid.value)}
                    className='no-print'
                >
                    {generateMonthOptions(expense.expenseID).map((option, index) => (
                        <option 
                            selected={index === 1} 
                            key={index}
                            className='no-print'
                        >
                            {option}
                        </option>
                    ))}
                </select>
            </div>
        ),
        actions: 
        rowLoading[expense.expenseID] ? (
          <div className="row-loader">
            <img src={loaderIcon} alt="Loading..." width={20} height={20} />
          </div>
        ) : 
        ((permission === 1) || (permission === 3 && !fields[`expenseID${expense.expenseID}`]?.paid.value)) && 
          (
            <button
              className="btn-add-more"
              style={{ fontSize: 10 }}
              onClick={(e) => handleDeleteClick(expense.expenseID, fields[`expenseID${expense.expenseID}`]?.paid.value)}
            >
                X
            </button>
          )
      };
    });
  } else {
    rows = expenses.map((expense, index) => {
      totalExpenses = totalExpenses + expense.amount;
      const DueDate = expense.dueDate.split('-');
      const updatedDate = `${DueDate[1]}/${DueDate[0]}`;
      
      return {
        expenseID: expense.expenseID,
        companyName: companies.find(c => c.companyID === expense.companyID)?.name,
        supplier: suppliers.find(c => c.supplierID === expense.supplierID)?.name,
        invoiceID: fields[`expenseID${expense.expenseID}`]?.invoiceID.value || '',
        retention: fields[`expenseID${expense.expenseID}`]?.retention.value ? 'Sim' : 'Não',
        payment: expense.paymentDate && expense.paymentDate != 'null' ? new Date(expense.paymentDate).toLocaleString("pt-BR", { timeZone: "America/Sao_Paulo", day: '2-digit', month: '2-digit', year: 'numeric' }) : '-',
        amount: Utils.currencyFormat(fields[`expenseID${expense.expenseID}`]?.amount.value) || '',
        comments: fields[`expenseID${expense.expenseID}`]?.comments.value || '',
        invoice_bill: fields[`expenseID${expense.expenseID}`]?.pdfPath.value.indexOf('https') !== -1 && <button style={{ background: 'transparent', border: '0' }} onClick={() =>setModalPDF({ status: true, url: fields[`expenseID${expense.expenseID}`]?.pdfPath.value })}><img style={{ width: '35px' }} src={invoiceIcon} alt="Acessar documento." /></button>,
        paid: fields[`expenseID${expense.expenseID}`]?.paid.value ? 'Sim' : 'Não',
        accounting: constructions.find(c => c.constructionID === (expense.accounting ? parseInt(expense.accounting, 10) : parseInt(expense.constructionID, 10)))?.name || 'Não Contabilizado',
        competence: updatedDate, // Replace with appropriate string
      };
   });
  }

  const handleDeleteExpense = async (expenseID) => {
    let pdfPath = fields[`expenseID${expenseID}`]?.pdfPath.value
    if(pdfPath.indexOf('https') !== -1) {
      pdfPath = fields[`expenseID${expenseID}`]?.pdfPath.value.split('documents/')[1].split('/')[1].replace('.pdf', '')
    } else {
      pdfPath = '';
    }
    try {
      setLoader(true);
      await expensesService.deleteExpense(expenseID, pdfPath);
      setDeleteModal({ status: false, deleteExpID: null, paid: false })
      setGetRequestComp(getRandomInt(1, 1000));
      setTimeout(() => {
        setLoader(false);
      }, 500);
    } catch (error) {
      console.error('Error deleting expense:', error);
    }
  };

  const colSpanMapping = {
    1: { colSpanTotal: 9, colSpanEnd: 10, colSpanTotalPrint: 5 },
    2: { colSpanTotal: 8, colSpanEnd: 4, colSpanTotalPrint: 5 },
    3: { colSpanTotal: 7, colSpanEnd: 5, colSpanTotalPrint: 5 },
    4: { colSpanTotal: 6, colSpanEnd: 4, colSpanTotalPrint: 5 },
  };
  
  const { colSpanTotal, colSpanEnd, colSpanTotalPrint } = colSpanMapping[permission] || {};
  const tableFooter = (
    <>
      <tr className='tfoot'>
        <td colSpan={colSpanTotalPrint} className='print' style={{textAlign: 'right'}}><strong>Total:</strong></td>
        <td  style={{textAlign: 'right', paddingLeft: '10px'}} colSpan={1} className='print'><strong><span>{ Utils.currencyFormat(totalExpenses) }</span></strong></td>
        <td  colSpan={1} className='print'>&nbsp;</td>
        <td colSpan={colSpanTotal} style={{textAlign: 'right'}} className='no-print'><strong>Total: { Utils.currencyFormat(totalExpenses) }</strong></td>
        <td colSpan={ colSpanEnd } style={{textAlign: 'right'}} className='no-print'></td>
      </tr>
    </>
  );

  const modalDelete = (
    <>
      <div className='modal-suppliers'>
        <div className="inner-content">
            <>
              <div className="wrapper-delete">
                <h3>Você realmente deseja remover esta despesa?</h3>
                { deleteModal.paid ? (<strong style={{ display: 'block', color: 'red', margin: '10px auto' }}>Esta despesa está paga!</strong>) : '' }
                <button className={'btn--primary'} onClick={()=> {
                  handleDeleteExpense(deleteModal.deleteExpID);
                }}>Sim</button>
                <button className={'btn--primary'} onClick={()=> { 
                  setDeleteModal({ status: false, deleteExpID: null, paid: false });
                  setHighlightedRow(-1);
                }}>Não</button>
              </div>
            </>
        </div>
      </div>
    </>
  );

  const handleOrderFields = (columnId, order) => {
    setOrderBy(prevOrderBy => {
      const isSameOrder = JSON.stringify(prevOrderBy) === JSON.stringify([[columnId, order.toUpperCase()]]);
      if (isSameOrder) {
          const newOrderBy = [columnId, prevOrderBy[1] === 'ASC' ? 'DESC' : 'ASC'];
          return newOrderBy;
      } else {
          const newOrderBy = [[columnId, order.toUpperCase()]];
          return newOrderBy;
      }
    });

    setTimeout(() => {
      setGetRequestComp(getRandomInt(1, 1000));
    }, 200);
  };
  
  return (
    <>
      <div className='conciliation-screen'>
        <FilterBar companies={companies} orderByT={orderBy} constructions={constructions} selectedDate={selectedDate} suppliers={suppliers} onFilterExpenses={handleFilterExpenses} requestComp={getRequestComp} typeSearchP={typeSearchString} />
        <DatePagination onDateChange={handleDateChange} />
        {
          loader ? (
            <img src={loaderIcon} alt="Carregando..." width={100} height={100} />
          ) : (
            <>
              <a onClick={handlePrintClick}>
                <img src={printIcon} width={24} height={24} alt="Imprimir" />
              </a>
              <h6 className='print'>Relatório de Pagamento e Conciliação</h6>
              { deleteModal.status && modalDelete }
              <ScrollableTable onHeaderClick={handleOrderFields} onDeleteClick={highlightedRow} headers={TableHeaders} height={'75vh'} footer={tableFooter} dataRows={rows} />
            </>
          )
        }
      </div>
      {
        modalPDF.status && <ModalPDF onCloseModal={handleCloseModal} url={modalPDF.url} />
      }
      {
        modalAttach.status && <ModalAttach onCloseModal={ handleCloseModal } onUploadSuccess={handleUrlUpload} expenseID={ modalAttach.expenseID } url={ modalAttach.url} mtId={modalAttach.mtId} dueDate={ modalAttach.dueDate } companies={ modalAttach.companies } companyID={ modalAttach.companyID } />
      }
    </>
  )
}

export default Conciliation;